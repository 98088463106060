<template lang="">
<div>
    <div class="col-md-12" v-if="viewno!=3">
        <div class="row" style="margin-top:20px">
            <div class="col-md-6" >
                <h3 class="page-header">
                <i class="bx bxs-chart animated bounceInDown show-info"></i>
                Followed Account
                </h3>
            </div>
        </div>
    </div>
    <div style="background-color: white" v-if="loginusercheck(130) && viewno!=3">
        <div class="col-md-12" >
            <div class="panel panel-cascade">
                <div class="panel-body ">                   
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-horizontal cascde-forms">
                                <div class="form-group row CHK">
                                    <label class="col-lg-4 col-md-3 control-label">Customer Name</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="customername" type="text"  />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-md-3 control-label">Zone<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                    <div class="col-lg-8 col-md-9">
                                        <select class="form-control form-cascade-control input-small" v-model="zoneid">
                                            <option selected="selected" :value="0">SELECT</option>
                                            <option v-for="item in getmastertype(MASTER_ZONE_TYPES)" :key="item.labelid" :value="item.labelid">{{item.label}}</option>
                                        </select>
                                    </div>
                                </div>               
                                
                                <div class="form-group row">
                                    <label class="col-lg-4 col-md-3 control-label">Remaining: FROM</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="remfrom" type="number"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 CHK">
                            <div class="form-horizontal cascde-forms">
                               
                                <div class="form-group row">
                                    <label class="col-lg-4 col-md-3 control-label">Customer LAN</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="manualid" type="text" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-md-3 control-label">Status</label>
                                    <div class="col-lg-8 col-md-9">
                                        <select class="form-control form-cascade-control input-small" v-model="accountstatus">
                                            <option :value="0">Dues Account</option>
                                            <option :value="1">Clear Account</option>
                                            <option :value="2">Deafulter Account</option>
                                            <option :value="3">Legal Account</option>
                                            <option :value="4">Concession Account</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-md-3 control-label">Remaining : TO</label>
                                    <div class="col-lg-8 col-md-9">
                                        <input class="form-control form-cascade-control input-small" v-model="remto" type="number" />
                                    </div>
                                </div>
                                <div class="form-group row">
        
                                    <div class="col-lg-12 col-md-9">
                                        <button class="btn btn-primary" @click="search()">Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="info-box bg-warning text-white">
                    <div class="info-icon bg-primary-dark" style="padding-bottom:5px;"> 
                        <h5>
                            <i class="fa fa-check-square-o"></i>
                            Select REC BY
                        </h5>
                    </div>
                    </div>
                    <div class="col-md-12">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-3" v-for="(item) in employees" v-bind:key="item.id">
                                    <!-- <usercheck :empid="item.id"></usercheck>  {{item.name}} -->
                                    <input type="checkbox" 
                                    :value="item.id"     
                                    id="item.id" 
                                    v-model="checkedCategories" 
                                    @change="check($event)">{{item.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <label class="col-lg-4 col-md-3 control-label" v-if="!loginusercheck(130) || viewno==3"> Accounts Followed by <span style="color:red">{{getname}}</span></label>
        <div class="col-md-12 pl-0">
            <div class="col-lg-4 col-md-4" v-if="loginusercheck(130) && viewno!=3" style="display:none">
                <select v-model="empid" class="form-control form-cascade-control input-small" @change="refresh()">
                    <option v-for="filter in employees" :key="filter.id" :value="filter.id">{{filter.name}}</option>
                </select>             
            </div>
            <div class="col-md-8">
                
            </div>
        </div>
    </div>
    <div class="col-md-12 mt-20" v-if="loginusercheck(130) && viewno!=3">
        <div class="row">
            <div class="col-md-2">
                <strong>Show Rec.</strong>
            </div>
            <div class="col-md-2">
                <select class="form-control col-mb-1" v-model="noofrec" @change="refresh()">
                    <option value=10>10</option>
                    <option value=25>25</option>
                    <option value=50>50</option>
                    <option value=100>100</option>
                </select>
            </div>
        </div>
    </div>
    <div class="col-md-12 pt-20 panel">
            <div class="flex-between-row panel-body">
                <div class="">
                    <span class="control-label">Total Remaining:</span>
                    <span class="badge bg-danger font-15" style="font-weight:bold">
                        <i class="fa fa-inr"></i>{{remainingamount}}</span>
                </div>
                <div class="">
                    <span class="control-label">Clear Acc:</span>
                    <span class="badge bg-seagreen">
                        <i class="fa fa-inr"></i>{{clearacc}}</span>
                </div>
                <div class="">
                    <span class="control-label">Unclear Acc:</span>
                    <span class="badge bg-danger">
                        <i class="fa fa-inr"></i>{{totalrec-clearacc}}</span>
                </div>
                <div class="">
                    <span class="control-label">Total Emi Amount:</span>
                    <span class="badge bg-seagreen font-14"><i class="fa fa-inr"></i>{{totaldueemi}}</span>
                </div>
            </div>
        </div>
    <div class="col-md-12">
        <div class="panel">
            <div class="panel-body">
                <table class="table table-bordered table-hover table-striped display" id="tblmanual">
                    <thead class="table-font">
                        <tr>
                            <th>S.No</th>
                            <th class="visible-lg">	Lan Account</th>
                            <th>Total Amount </th>
                            <th>Down Payment</th>
                            <th class="visible-lg">	EMI Amount</th>
                            <th>Dues Amt.</th>
                            <th class="visible-lg">Paid Amount</th>
                            <th class="visible-lg">Remaing Amt</th>
                            <th>Fallow By</th>
                        </tr>
                    </thead>
                    <tbody class="table-font">
                        <tr v-for="item,index in followed" :key="item.id">
                            <td>{{((currentpage-1) * noofrec) + (index+1)}}</td>
                            <td><span >{{item.manualid}}</span></td>
                            <td><span >{{item.billedamount}}</span></td>
                            <td>{{item.downpayment}}</td>
                            <td><span >{{item.emi}}</span></td>
                            <td><span style="color:orange;font-weight:bold">{{getactiveemi(item)}}</span></td>
                            <td><span >{{item.totalreceived}}</span></td>
                            <td><span style="color:red;font-weight:bold">{{item.remainingamount}}</span></td>
                            <td><span style="font-weight:bold" v-if="item.loanfollowed!=null">{{item.loanfollowed.name}}</span></td>
                        </tr>
                    </tbody>
                </table>
                <div class="col-md-12" style="margin-top: 20px">
                    <nav aria-label="Page navigation example" style="text-align: right !important">   
                    <paginate
                        style="margin: 0px !important; float: right"
                        :page-count="noofpages"
                        :click-handler="clickCallback"
                        :prev-text="'&laquo;'"
                        :next-text="'&raquo;'"
                        :container-class="'pagination'">
                    </paginate>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex';
import Constants from '../../components/utilities/Constants.vue';
import Paginate from "vuejs-paginate";
export default {
    mixins:[Constants],
    props:['followedid'],
    components:{Paginate},
    data() {
        return {
            followed:[],
            empid:'',
            checkedCategories:[],
            customername:'',
            manualid:'',
            remto:'',
            remfrom:'',
            zoneid:0,
            accountstatus:0,
            remainingamount:0,
            totalrec:0,
            noofrec:25,
            currentpage:1,
            totaldueemi:0,
            clearacc:0,
            unclearacc:0
        }
    },
    computed:{
        ...mapGetters(['loggedinuser','employees','viewno']),
        getname(){
            if(this.employees!=null){
                let followname=[];
                followname=this.employees.find(block=>block.id==this.empid)
                if(followname!=null){
                    return followname.name
                }
                return '------'
            }else{
                return '......'
            }
        },
        noofpages(){
            return Math.ceil(parseInt(this.totalrec) / parseInt(this.noofrec))
        }
    },
    mounted(){
        this.$store.commit('assignloadingstatus',1)
        setTimeout(() => {
            if(this.$router.currentRoute.path=='/loanfollowed'){
                this.$store.dispatch('fetchemployees')
                if(this.empid==''&& this.loggedinuser!=null){
                    
                    this.empid=this.loggedinuser.id
                    this.refresh()
                }
            }else{
                this.empid=this.followedid
                this.refresh()
            }
        }, 1000);
    },
    methods: {
        clickCallback (currentpage) {
            this.currentpage=currentpage
            this.$store.commit("assigncurrentpage",currentpage);
            this.refresh()
        },
        refresh(){
            this.$store.commit('assignloadingstatus',1)
            console.log("router:")
            if(!this.loginusercheck(130) || this.viewno==3){
                console.log('stage1')
                this.checkedCategories.push(this.empid)
            }
            let param={userid:this.checkedCategories,customername:this.customername,manualid:this.manualid,
                remto:this.remto,remfrom:this.remfrom,zoneid:this.zoneid,accountstatus:this.accountstatus,
            noofrec:this.noofrec,currentpage:this.currentpage}
            window.axios.post('api/followed/account/byid',param)
	    		.then(response=>this.processResponse(response.data))
	    		.catch(error=>console.log(error));
            window.axios.post('api/followed/dues/emi',param)
	    		.then(response=>this.processDuesEmiResponse(response.data))
	    		.catch(error=>console.log(error));
            window.axios.post('api/followed/account/byid/clearacc',param)
	    		.then(response=>this.processDuesEmiClearResponse(response.data))
	    		.catch(error=>console.log(error));
        },
        processDuesEmiClearResponse(data){
            this.clearacc=data.countrec
        },
        processDuesEmiResponse(data){
            this.totaldueemi=data
        },
        processResponse(data){
            this.$store.commit('assignloadingstatus',0)
            this.followed=data.account
            this.remainingamount=data.remaining
            this.totalrec=data.countrec
        },
        back(){
            this.$store.commit('assignloadingstatus',0)
            this.$store.commit('assignviewno',1)
        },
        check (e) {
            this.$nextTick(() => {
                console.log(this.checkedCategories, e)
            })
            this.refresh()
        },
        search(){
            this.currentpage=1
            this.refresh()
        },
        getactiveemi(item){
            let tobe=0;
            item.emis.forEach(element => {
                if(element.isactive==1){
                    console.log(element)
                    console.log("found")
                    tobe= element.tobepaid
                }
            });
            return tobe
        },
        
    },
}
</script>